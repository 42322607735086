.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: unset !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset !important;
}

.custom-radio-btn.ant-radio-button-wrapper {
    padding-inline: unset !important;
    line-height: unset !important;
}

.bg-header {
    background: linear-gradient(180deg, rgba(52, 58, 78, 0.4) -95.4%, rgba(217, 217, 217, 0) 100%);

}
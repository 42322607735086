.visibleStake {
    position: absolute;
    right: 0;
    bottom: -5px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    animation: visibleTotalStake 0.5s forwards;
}

.hiddenStake {
    display: none;
}

@keyframes visibleTotalStake {
    from {
        width: 0px;
    }

    to {
        @media screen and (max-width:1365px) {
            width: 300px;
        }

        @media screen and (max-width:1920px) {
            width: 378px;
        }
    }
}
.custom-paginaton.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    color: #5E7EBB !important;
}

.custom-paginaton.ant-pagination .ant-pagination-prev {
    border: 1px solid #202531;
    border-radius: unset !important;
    margin-inline-end: unset !important;
}

.custom-paginaton.ant-pagination .ant-pagination-next {
    border: 1px solid #202531;
    border-radius: unset !important;
}

.custom-paginaton.ant-pagination .ant-pagination-item-active {
    background: #43517F !important;

}

.custom-paginaton .ant-pagination-item {
    border-color: #202531 !important;
    background-color: #131620 !important;
    border-radius: unset !important;
    margin-inline-end: unset !important;
}

.custom-paginaton.ant-pagination .ant-pagination-item a {
    color: #5E7EBB !important;
}

.custom-paginaton.ant-pagination .ant-pagination-next button {
    color: #5E7EBB !important;
}

.custom-paginaton.ant-pagination .ant-pagination-prev button {
    color: #5E7EBB !important;
}

.custom-paginaton.ant-pagination .ant-pagination-item-active a {
    color: white !important;
}
.bg-btn-group {
    background: linear-gradient(99.49deg, #8A93B4 3.93%, #BAC1DC 32.27%, #858DAE 60.61%, #7B81A3 88.95%);
}


.tab-bet-soccer.tab-bet-mgm .ant-empty-description {
    display: none;
}

.tab-bet-soccer .ant-tabs-tab {
    margin: 0 !important;
    height: 33px !important;
    min-width: 56px !important;
    padding: 6px !important;
    border: 1px solid rgba(86, 95, 115, 0.2) !important;
}
.tab-bet-soccer .ant-tabs-tab.ant-tabs-tab-disabled{
    display: none !important;
}
.tab-bet-soccer .ant-tabs-tab-active {
    background: linear-gradient(99.49deg, #8A93B4 3.93%, #BAC1DC 32.27%, #858DAE 60.61%, #7B81A3 88.95%) !important;
    border-radius: 2px;
}

.tab-bet-soccer .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
}

.tab-bet-soccer .ant-tabs-tab .ant-tabs-tab-btn {
    color: white !important;
    /* font-family: Oswald; */
    font-size: 16px;
    font-weight: 100;
    line-height: 21px;
    text-align: center !important;
    text-transform: uppercase;
    width: 100%;

}

.tab-bet-soccer .ant-tabs-nav-wrap {
    padding: 0px 8px !important;
}

.tab-bet-soccer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #20273D !important
}

.place-bet-soccer .text-black span {
    color: #000000;
}

.tab-bet-soccer .ant-empty-image {
    display: none;
}

.border-warning-betslip {
    border: 1px solid #D4B962
}

.Toastify__toast {
    background: var(--Color-Global-Gray, #20273D) !important;

}

.modalBets .ant-modal-content {
    background: var(--Color-Global-Gray, #20273D) !important;
}

.modalBets .ant-modal-title {
    background: var(--Color-Global-Gray, #20273D) !important;
}

.button-font-bold-GT-America span {
    font-weight: bold;
}

.betslip-mgm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #20273D !important
}

.betslip-mgm .ant-tabs-tab {
    margin: 0 !important;
    height: 33px !important;
    min-width: 56px !important;
    padding: 6px !important;
    border: 1px solid rgba(86, 95, 115, 0.2) !important;
}

.betslip-mgm .ant-tabs-tab-active {
    background: linear-gradient(99.49deg, #8A93B4 3.93%, #BAC1DC 32.27%, #858DAE 60.61%, #7B81A3 88.95%) !important;
    border-radius: 2px;
}

.betslip-mgm .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
}

.betslip-mgm .ant-tabs-tab .ant-tabs-tab-btn {
    color: white !important;
    /* font-family: Oswald; */
    font-size: 16px;
    font-weight: 100;
    line-height: 21px;
    text-align: center !important;
    text-transform: uppercase;
    width: 100%;

}

.betslip-mgm .ant-tabs-nav-wrap {
    padding: 0px 12px !important;
}

.betslip-mgm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #20273D !important
}

.betslip-mgm .ant-empty-image {
    display: none;
}

.betslip-mgm {
    background-color: #131620;
}

.betslip-mgm .ant-tabs-tab.ant-tabs-tab-disabled {
    display: none;
}

.toast-component-global .Toastify .Toastify__toast-container {
    width: 300px !important;
}

.ant-progress-bg.ant-progress-bg-outer {
    height: 4px !important;
}

/* .tab-bet-soccer .ant-tabs-content.ant-tabs-content-top {
    display: none;
} */
.gameContent {
    max-width: 724px;
}

.sideBar {
    width: 320px;
    padding-right: 16px;
    flex-shrink: 0;
}

.sideBar .ant-input-prefix {
    margin-inline-end: 8px !important;
}

.sideBar [data-menu-id*="bg-BB271A"] {
    background-color: #BB271A !important;
    color: white !important;
}

/* .sideBar li[role="menuitem"][tabindex="-1"] {
    padding-left: 30px !important;
} */

.betSlip {
    /* padding: 10px 10px 20px 10px; */
    padding: 0px 0px 0px 0px;
    border-radius: 8px 8px 8px 8px;
}

.betSlip .anticon-close {
    justify-content: flex-end !important;
}

.betSlip .slipNumber {
    background: #7EA2FF;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    color: white;
    line-height: 18px;
    font-weight: 700;
    font-size: 12px;
}

.sideBar .ant-menu-title-content {
    text-align: left;
}

.countryNumber span:nth-child(2) {
    border-radius: 4px;
    background: #F0F0F0;
    padding: 0px 4px 2px 4px;
    line-height: 19px;
    height: 19px;
}

.gameBreadCrumb .ant-breadcrumb ol li {
    display: flex;
    align-items: center;
}

.gameBreadCrumb .ant-breadcrumb ol .ant-breadcrumb-separator {
    color: #D5D5D5 !important;
}

.ant-menu-item-active .countryNumber span:nth-child(2) {
    background: #D5D5D5 !important;
}

.countryNumber {
    align-items: center;
}

/* .collapes-home .ant-menu-submenu .ant-menu-sub.ant-menu-inline .ant-menu-submenu-selected .ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
    color: #BB271A;
    background-color: #E7E7E7 !important;
    font-weight: 700;
} */

.collapes-home .ant-menu.ant-menu-sub.ant-menu-inline ul.ant-menu.ant-menu-sub .ant-menu-item-active {
    color: #BB271A !important;
    font-weight: 700;
    background-color: transparent !important;
}

.collapes-home ul.ant-menu.ant-menu-sub .ant-menu-item-active {
    color: black !important;
    background-color: white !important;
}

.collapes-home ul.ant-menu.ant-menu-sub .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #BB271A !important;
    background-color: #E7E7E7 !important;
}

.headTab>.ant-tabs-nav {
    margin-bottom: unset !important;
}

.sideBar .ant-menu-submenu-title {
    padding-left: 26px !important;
}

.sideBar .ant-menu-item.ant-menu-children-item-soccer {
    padding-left: 0px !important;
    margin-block: 0px;
    width: 100%;
    border-radius: 0px;
}


.sideBar .ant-menu-item {
    padding-left: 26px !important;
}

.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title {
    background-color: #BB271A !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title span,
.collapes-home>.ant-menu-submenu>.ant-menu-submenu-title i {
    color: white !important;
}

.gameBreadCrumb .ant-tabs-tab-active span {
    color: #7EA2FF !important;
    font-weight: 700 !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: unset !important;
}

.gameBreadCrumb .ant-tabs-nav-wrap {
    margin-left: 36px;
}

.gameBreadCrumb .ant-tabs-nav {
    margin-bottom: unset !important;
}

.betSlip .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px !important;
}

.betSlip .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
}

.betSlip .ant-tabs-tab .ant-tabs-tab-btn {
    color: #D5D5D5;
    font-weight: 500;
}

.betSlip .ant-tabs .ant-tabs-ink-bar {
    background: #BB271A !important;
}

.betClippers {
    border-bottom: #E7E7E7 solid 1px;
    padding-bottom: 24px;
}

.matchClippers {
    border-bottom: #E7E7E7 solid 1px !important;
}

.matchLiveClippers {
    border-top: #E7E7E7 solid 1px !important;
}

.totalStake {
    box-shadow: 0px -8px 10px 0px rgba(126, 162, 255, 0.10);
    padding: 10px 10px 10px 10px;
    border-radius: 8px;
}

.totalStake .ant-tabs-nav-wrap {
    justify-content: flex-end;
}

.totalStake .ant-tabs .ant-tabs-ink-bar {
    background: unset !important;
}

/* .gameRatio .homeRaito:nth-child(1) .ratioDetail:nth-child(1) {
    background-color: #D4B962;
} */
.totalStake .stakeButton {
    padding: 4px 10px;
    border-radius: 8px;
    border: 1px solid var(--Primary-5, #ffffff);
    /* background: var(--Neutral-25, #FCFCFD); */
    box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.20);
}

.autoPlus button {
    height: 40px;
    margin-bottom: 6px;
    font-weight: 700;
}

.keyBoard button {
    width: 52px;
    height: 52px;
    background: var(--7-ea-2-ff-20, rgba(126, 162, 255, 0.20));
    border: unset !important;
    font-weight: 700;
}

.bg-gradient-header {
    background: linear-gradient(90deg, #000000 0%, #F50000 100%, #E70073 0%) !important;

}

.todayMatch {
    background: var(--7-ea-2-ff-20, rgba(126, 162, 255, 0.20));
}

/* .gameDetail:hover,
.gameDetail:hover .gameTime {
    background: #131620 !important;
} */

.progressBar .ant-progress-text {
    display: none;
}

.progressBar .ant-progress-line {
    margin-inline-end: unset !important;
}

.totalStake .ant-btn-primary:disabled {
    background: var(--d-4-b-962-gold-mgm, #D4B962);
    color: white;
}

.ant-tabs-tab-btn[aria-disabled="false"] {
    color: #000000 !important;
}

.betSlip .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
    color: #20273D !important;
    font-weight: 700 !important;
}

.canPlayModal .ant-btn-primary {
    color: white;
    background-color: #D4B962;
}

.canPlayModal .ant-btn-primary:hover {
    background-color: #D4B962 !important;
    /* color: white; */
}

.canPlayModal .ant-btn-default {
    background-color: white;
}

.canPlayModal .ant-btn {
    width: 132px;
}

.canPlayModal .ant-modal-content {
    border-radius: 12px;
    background: var(--262626, #262626) !important;
    width: 300px;
    padding: 24px 12px 12px 12px !important;
}

.canPlayModal .ant-modal-footer {
    margin-top: 0px;
}

.canPlayModal .ant-modal-header {
    margin-bottom: 0px;
    background: var(--262626, #262626);
}

.canPlayModal .ant-modal-body {
    color: white;
    text-align: center;
}

.canPlayModal .ant-modal-body p {
    padding: 4px 5px 20px 5px;
    font-size: 16px;
}

.canPlayModal .anticon-close {
    color: white;
}

.canPlayModal .ant-modal-title {
    display: flex;
    justify-content: center;
    color: #52C41A !important;
}

.canPlayModal .ant-modal-header {
    background: var(--262626, #262626) !important;
}

.canPlayModal .anticon-check-circle svg {
    width: 36px;
    height: 36px;
}

.canPlayModal.cancelConfirmBet .ant-modal-title {
    display: flex;
    justify-content: center;
    color: #BB271A !important;
}

.billRefund .ant-modal-content {
    display: flex;
    justify-content: center;
    width: 300px;
}

.billRefund .billBorder {
    border-bottom: 1px solid #000000;
    margin: 2px 0px 2px 0px;
    /* padding-bottom: 5px;
    padding-top: 5px; */
}

.betSlip .ant-empty-description {
    color: #898989;
    font-size: 13px;
}

.stakeDetail {
    box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.20);
}

.cantPlayModal .ant-modal-footer .ant-btn-primary {
    display: none;
}

.cantPlayModal .ant-modal-footer .ant-btn-default {
    width: 100%;
    background-color: #D4B962;
    color: white;
    border: unset;
}

.checkBoxAll svg {
    width: 18px;
    height: 18px;
}

.checkBoxAll svg path {
    border-radius: 6px;
}

.NFLComponent .statusBar {
    margin-top: 4px;
}

.NFLComponent .winBar {
    margin-top: 4px;
}

.NFLstatus .liveCam {
    width: 135px;
}

.soccerTop {
    border: 1px #505050 solid;
    border-radius: 6px;
}




.sideBar .ant-menu-item {
    /* width: 100% !important; */
}

/* .sideBar .ant-menu-submenu-title {
    margin: 4px !important;

} */

.dayTime .ant-radio-wrapper-checked .ant-radio-checked:after {
    position: absolute;
    top: 0px;
    left: 0px;
}

.dayTime .ant-radio-wrapper {
    position: relative;
}

.betTabs .ant-checkbox-inner:after {
    position: absolute;
    top: 7px;
    right: 6px;
}

.sideBar .ant-menu-item {
    padding-right: 24px;
}

.sideBar li:focus {
    outline: none !important;
}

.sideBar li:active {
    outline: none !important;
}

.sideBar .ant-menu-submenu-title {
    margin-inline: unset !important;
    width: 100% !important;
    position: relative;
}

.sideBar .ant-menu-item {
    margin-inline: unset !important;
}

.h-content-detail {
    height: calc(100vh - 380px);
    border-radius: 8px;
}

.h-content-home {
    height: calc(100vh - 470px);
    border-radius: 8px;
}

.h-body-main {
    height: calc(100vh - 150px);
}

.h-page-home {
    height: calc(100vh - 117px);
}
.custom-date-picker .ant-picker-footer {
    display: none;
}

.custom-date-picker.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #D4B962;
}

.custom-popup-picker-mobile .ant-picker-panels {
    width: calc(100vw - 155px);
    overflow-x: scroll;
}

.custom-popup-picker-mobile .ant-picker-date-panel {
    width: calc(50vw) !important;
}
.break-page {
    page-break-after: always;
    --webkit-page-break-after: always;
    page-break-inside: always;
    break-inside: always;
    page-break-before: always;
}

.billRefund .ant-modal-content .ant-modal-body {
    width: 100%;
}

.cantPlayModal .anticon-warning svg {
    fill: #D4B962;
    width: 40px;
    height: 40px;
}

.cantPlayModal.canPlayModal .ant-modal-content {
    height: 210px;
}

.ant-modal-wrap {
    display: flex;
    align-items: center;
}

.ant-modal {
    padding-bottom: 200px;
}

.loadingModal .ant-modal-content {
    height: 130px;
}

.withDrawModal .anticon-wallet svg {
    fill: #7EA2FF;
    width: 32px;
    height: 32px;
}

.deleteAll .anticon-question-circle svg {
    fill: #BB271A;
    width: 32px;
    height: 32px;
}

.withDrawModal .ant-modal-content {
    height: 260px;
}

.canPlayModal.withDrawModal .ant-btn-primary {
    background-color: #D4B962;
}

.continueBetting .ant-modal-title svg {
    width: 40px;
    height: 40px;
}

.canPlayModal.withDrawModal.continueBetting .ant-btn-primary {
    background-color: #52C41A;
}

.canPlayModal.continueBetting .ant-btn {
    background-color: #7EA2FF;
    border: unset;
    color: white;
}

.canPlayModal.withDrawModal .ant-modal-body p {
    padding: 2px 5px 20px 5px;
}

.canPlayModal.deleteAll .ant-modal-body p {
    padding: 6px 5px 20px 5px;
    font-weight: 700;
    margin-bottom: 30px;
}

.canPlayModal.deleteAll .ant-btn-primary {
    background-color: #BB271A;
}

.canPlayModal.withDrawModal.confirmBets .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.rotating {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom_checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #DBBF64 !important;
    border-color: #DBBF64 !important;
}

.custom_checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #DBBF64 !important;
}

.custom_checkbox .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #DBBF64 !important;
}
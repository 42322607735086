@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GT-America-Compressed-Regular-Trial';
  src: url(./assets/fonts/GT-America/GT-America-Compressed-Regular-Trial.otf);
}
@font-face {
  font-family: 'GT-America-Compressed-Medium-Trial';
  src: url(./assets/fonts/GT-America/GT-America-Compressed-Medium-Trial.otf);
}
@font-face {
  font-family: 'GT-America-Compressed-Bold-Trial';
  src: url(./assets/fonts/GT-America/GT-America-Compressed-Bold-Trial.otf);
}
/* Thiết lập mặc định với scrollbar cho Chrome trên web */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131620;
}

/* Tùy chỉnh cho scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #D4B962 !important;
  border-radius: 10px;
}


.dateFilter-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Ẩn scrollbar trên di động */
@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar-thumb {
    display: none;
  }

}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
p,
span,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
}
.font-medium-GT-America{
  font-family: 'GT-America-Compressed-Medium-Trial';
}
.font-bold-GT-America{
  /* font-family: 'GT-America-Compressed-Bold-Trial'; */
  font-weight: 700;
}
.width-correct-score {
  width: 12.5%;
}

.key-frame-light-green {
  animation-name: example;
  animation-duration: 5s;
}

.key-frame-light-red {
  animation-name: examplered;
  animation-duration: 5s;
}

@keyframes example {
  0% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }

  10% {
    background-color: black;
  }

  20% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }

  30% {
    background-color: black;
  }

  40% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }

  50% {
    background-color: black;
  }

  60% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }

  70% {
    background-color: black;
  }

  80% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }

  90% {
    background-color: black;
  }

  100% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgb(32, 39, 61) 100%);
  }
}

@keyframes examplered {
  0% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }

  10% {
    background-color: black;
  }

  20% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }

  30% {
    background-color: black;
  }

  40% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }

  50% {
    background-color: black;
  }

  60% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }

  70% {
    background-color: black;
  }

  80% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }

  90% {
    background-color: black;
  }

  100% {
    background: linear-gradient(180deg, #BB2C38 0%, rgb(32, 39, 61) 100%);
  }
}

.collapse-soccer-live {
  width: 100%;
}

.collapse-soccer-live .ant-collapse-item .ant-collapse-header {
  width: 100%;

}

.collapse-soccer-live .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.waiting {
  animation-name: waiting;
  animation-duration: 20s;
}

@keyframes waiting {
  0% {
    color: #D4B962;
  }

  5% {
    color: #FF4A59;
  }

  10% {
    color: #D4B962;
  }

  15% {
    color: #FF4A59;
  }

  20% {
    color: #D4B962;
  }

  25% {
    color: #FF4A59;
  }

  30% {
    color: #D4B962;
  }

  35% {
    color: #FF4A59;
  }

  40% {
    color: #D4B962;
  }

  45% {
    color: #FF4A59;
  }

  50% {
    color: #D4B962;
  }

  55% {
    color: #FF4A59;
  }

  60% {
    color: #D4B962;
  }

  65% {
    color: #FF4A59;
  }

  70% {
    color: #d49962;
  }

  75% {
    color: #FF4A59;
  }

  80% {
    color: #D4B962;
  }

  85% {
    color: #FF4A59;
  }

  90% {
    color: #D4B962;
  }

  95% {
    color: #FF4A59;
  }

  100% {
    color: #D4B962;
  }
}

.ant-tabs-nav-operations {
  display: none !important;
}
.tab-bet-home .ant-tabs-content-holder {
  height: calc(100vh - 366px);
  overflow: auto;
}

.tab-bet-home-mgm .ant-tabs-content-holder {
  height: calc(100vh - 380px);
  overflow: auto;
}

/* .tab-bet-home .ant-tabs-content-holder::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 6px;
}

.tab-bet-home .ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background: #D4B962;
  border-radius: 16px;
} */

.tab-bet-home-none .ant-tabs-content-holder {
  display: none;
}
.tab-bet-visible-soccer .ant-tabs-content-holder {
  display: block;
  height: calc(var(--inner-height) - 520px) !important;
  overflow-y: scroll;
}
.tab-bet-visible .ant-tabs-content-holder {
  display: block;
  height: calc(var(--inner-height) - 530px) !important;
  overflow-y: scroll;
}
.betTabs::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 6px;
}

.betTabs::-webkit-scrollbar-thumb {
  background: #D4B962;
  border-radius: 16px;
}

.betTabs {
  /* max-height: 500px; */
  overflow: auto;
  /* position: relative; */
  padding-bottom: 10px;
  padding-right: 10px;
}
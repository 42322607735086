.ant-dropdown {
    background: unset !important;
}

.ant-dropdown .ant-dropdown-menu {
    padding: 14px 24px 16px 24px !important;
    background-color: #131620 !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 32px 0px rgba(47, 47, 47, 0.25) !important;
}

.ant-dropdown .ant-dropdown-arrow:before {
    background: #131620 !important;
}

.div-statement-hover:hover>.statement-hover-hidden {
    display: none !important;
}

.div-statement-hover:hover>.statement-hover {
    display: block !important;
}